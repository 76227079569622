<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form @submit.prevent="handleSubmit(accept)">
        <div class="p-3">
          <div class="row">
            <div class="col-12">
              <div class="biz-input-group mb-3">
                <label>メールアドレス</label>
                <b-input style="background:none; color: #fff; border: none;" :value="$store.state.user.displayCode" disabled readonly/>
              </div>
              <validation-provider v-if="!isSetup" name="現在のパスワード" :rules="{required: true }" v-slot="{ errors }">
                <b-form-group class="biz-input-group mb-0" label="現在のパスワード">
                  <b-input-group>
                    <b-form-input :type="currentVisible?'text':'password'" v-model="current" />
                    <b-input-group-addon>
                      <j-button buttonType="button" @click="currentVisible=!currentVisible" class="no-min btn-light-link">
                        <font-awesome-icon icon="eye"/>
                      </j-button>
                    </b-input-group-addon>
                  </b-input-group>
                </b-form-group>
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </validation-provider>
              <validation-provider class="d-block mt-3" name="新しいパスワード" :rules="{required: true, passwordStrength: true }" v-slot="{ errors }">
                <b-form-group class="biz-input-group mb-0" label="新しいパスワード">
                  <b-input-group>
                    <b-form-input :type="passwordVisible?'text':'password'" v-model="password" />
                    <b-input-group-addon>
                      <j-button buttonType="button" @click="passwordVisible=!passwordVisible" class="no-min btn-light-link">
                        <font-awesome-icon icon="eye"/>
                      </j-button>
                    </b-input-group-addon>
                    
                  </b-input-group>
                </b-form-group>
                <pre class="invalid-feedback d-block override-font w-100" style="white-space:pre-wrap;">{{ errors[0] }}</pre>
              </validation-provider>
              <validation-provider class="d-block mt-3" name="新しいパスワード（再入力）" :rules="{ required: true }" v-slot="{ errors }">
                <b-form-group class="biz-input-group mb-0" label="新しいパスワード（再入力）">
                  <b-input-group>
                    <b-form-input :type="passwordAgainVisible?'text':'password'" v-model="passwordAgain" />
                    <b-input-group-addon>
                      <j-button buttonType="button" @click="passwordAgainVisible=!passwordAgainVisible" class="no-min btn-light-link">
                        <font-awesome-icon icon="eye"/>
                      </j-button>
                    </b-input-group-addon>
                  </b-input-group>
                </b-form-group>
                <pre class="invalid-feedback d-block override-font w-100" style="white-space:pre-wrap;">{{ errors[0] }}</pre>
              </validation-provider>
              <div class="biz-input-group mb-3">
                <label>本務選択</label>
                <select class="custom-select my-0" v-model="userOrgMain" v-if="!dutyInfo.registeredEmail" disabled>
                  <option v-for="orgSwitch in userOrgSwitch" :key="orgSwitch.code" :value="orgSwitch.code">
                    [{{ orgSwitch.organizationCode }}] {{ orgSwitch.organizationName }} ({{ orgSwitch.companyFullName }})
                  </option>
                </select>
                <select class="custom-select my-0" v-model="userOrgMain" v-if="dutyInfo.registeredEmail">
                  <option v-for="orgSwitch in userOrgSwitch" :key="orgSwitch.code" :value="orgSwitch.code">
                    [{{ orgSwitch.organizationCode }}] {{ orgSwitch.organizationName }} ({{ orgSwitch.companyFullName }})
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="controls text-center mt-2">
          <j-button class="mt-3 login-button" buttonType="submit">登録</j-button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import UserInfo from '@/model/UserInfo';

export default {
  props: ['user', 'isSetup', 'temporaryPassword'],
  data: function(){
    return {
      current: "",
      currentVisible: false,
      password: "",
      passwordVisible: false,
      passwordAgain: "",
      passwordAgainVisible: false,
      userOrgSwitch: this.user.userOrgSwitch,
      userOrgMain: this.user.code,
      dutyInfo:{
        duty: false,
        registeredEmail: false,
      }
    }
  },
  created: function(){
    this.load();
    this.searchDuty();
  },
  methods:{
    accept(){
      if(this.password != this.passwordAgain){
        this.$warningMessage("新しいパスワードと新しいパスワード（再入力）が一致しません。");return;
      }
      UserInfo.changePassword(this.$store.state.user.companyCode, {
        userCode: this.user.code,
        isGenerate: false,
        isSelf: true,
        password: this.password,
        currentPassword: this.current
      }).then(() => {
        this.$bvModal.hide(this.modalId);
        this.$successMessage('パスワードが変更されました。');
        this.settingDuty();
        this.$emit('save', this.userOrgMain);
      })
        .catch((reason) => {
          const appCode = reason.response.data;
          switch (appCode) {
            case "OA_0001":
              this.$errorMessage(
                "過去に設定されたパスワードです。別のパスワードを設定してください。"
              );
              break;
            case "OA_0002":
              this.$errorMessage("現状のパスワードが正しくありません。");
              break;
            case "OA_0003":
              this.$errorMessage(
                "The password does not comply with password rules"
              );
              break;
            default:
              this.$errorMessage("Error occurred while changing password.");
              break;
          }
        });
      
    },
    load(){
      this.current = this.temporaryPassword;
      this.password = "";
      this.passwordAgain = "";
    },
    searchDuty(){
      UserInfo.searchDuty(this.$store.state.user.companyCode,this.user.displayCode
      ).then(result =>{
        this.dutyInfo = result.data;
        if(this.dutyInfo.duty===true){
          // Remove this line
          // Copy from commit: "DEV-2023-5345 : change password rule" in develop branch
          // this.$router.push({ name: "Login" });
        }
      }).catch(reason => {
        console.error(reason);
        this.$router.push({name:'Login'});
      });
    },
    settingDuty(){
      var dutyUser = this.userOrgSwitch.find(u => u.code === this.userOrgMain)
      UserInfo.settingDuty(this.$store.state.user.companyCode,dutyUser
      ).catch(reason => {
        console.error(reason);
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  .login-button{
    width: 100%;
    padding: .8rem 1rem;
    background: #1a9bd5;
    border:none;
    box-shadow: none;
    transition: all .2s ease-in;
    &:hover{
      background: #559f59;
    }
  }

  .biz-input-group{
    color:#fff !important;
  }
</style>