<!-- Copy from commit: "DEV-2023-5345 : change password rule" in develop branch -->
<template>
  <div class="page">
    <div v-if="loaded">
      <div class="card login-card">
        <div class="card-body">
          <div class="text-center pt-5 pb-3">
            <img style="width:30%; height:auto;" src="@/assets/pb_logo_1_remove_bg_100x125.png">
          </div>
          <div class="text-white">
            <h4><center>パスワード変更</center></h4>
            <p class="text-center">前回のパスワード登録から一定期間が経過したため、パスワードを変更してください。</p>
          </div>
          <PasswordSetup :isSetup="false" temporaryPassword="" :user="$store.state.user" @save="onSubmit"/>
          <div class="text-center mt-5 mb-3">
            <img style="height: 20px; width: auto;" src="@/assets/pb_logo_1.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import LoginModel from '@/model/Login.js';
  import PasswordSetup from '@/components/user/PasswordSetup.vue';
  export default {
    components: { PasswordSetup },
    data: function(){
      return { 
        loaded: false,
      }
    },
    created: function(){
        this.loaded = true;
        this.$store.commit("passwordSetup", {
          accessToken: this.$route.params.accessToken,
          user: this.$route.params.user
        });
    },
    methods: {
      onSubmit(userOrgMain) {
        if(this.$store.state.user.code === userOrgMain){
          this.$store.commit("login", {
            accessToken: this.$store.state.accessToken,
            user: this.$store.state.user,
          });
          if (this.$store.state.user.status.accountCreated) {
            this.$router.push({ name: "MainMenu" });
          } else {
            this.$router.push({ name: "InviterPolicy" });
          }
        }else{
          var newUser = this.$store.state.user.userOrgSwitch.find(u => u.code === userOrgMain)
          LoginModel.switchUserOrg(newUser).then(result => {
            this.$store.commit("login", {
              accessToken: result.headers.authorization,
              user: result.data,
            });
            if (this.$store.state.user.status.accountCreated) {
              this.$router.push({ name: "MainMenu" });
            } else {
              this.$router.push({ name: "InviterPolicy" });
            }
          }).catch(reason => console.error(reason));
        }
      },
    }
  }
</script>
<style lang="scss" scoped>
  .login-card{
    background:rgba(35, 47, 62, 1);
    max-width: 450px;
    margin: 10px auto;
    border-radius: 1rem;
    .card-body {
      padding: 1rem 2.5rem 1rem 2.5rem;
      input {
        padding: .375rem .75rem;
        height: 38px;
        line-height: 1.5;
      }
    }
  }
  .login-button{
    width: 100%;
    padding: .8rem 1rem;
    background: #1a9bd5;
    border:none;
    box-shadow: none;
    transition: all .2s ease-in;
    &:hover{
      background: #559f59;
    }
  }
</style>